import 'normalize.css';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { config } from './setup';

import React from 'react';
import * as ReactDOM from 'react-dom';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { loadableReady } from '@loadable/component';

import { ROOT_CONTEXT } from '@opentelemetry/api';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
  SEMRESATTRS_SERVICE_NAMESPACE,
} from '@opentelemetry/semantic-conventions';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { Token } from '@ha/auth/browser';
import { track, getAnonymousId } from '@hbf/analytics';
import { createLogger } from '@hbf/log';

import { Environment } from 'ha/config';
import { HttpHeaders } from 'ha/constants/HttpHeaders';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { FetchClient } from 'ha/helpers/FetchClient';
import { createIntl } from 'ha/i18n';
import {
  sendTouchPointsList,
  trackTouchPoint,
  session,
} from 'ha/modules/Affiliation';
import { setupAnalytics } from 'ha/modules/Analytics/actions';
import { initSession } from 'ha/modules/AuthLogic/actions/initSession';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { createConsentClient } from 'ha/modules/CookieConsent';
import { setupMediaQueryListeners } from 'ha/modules/UI/actions';
import { getInitialState } from 'ha/myredux/getInitialState';
import { createAlgoliaService as algoliaService } from 'ha/services/algolia';
import { getAppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';
import { ConsistencyToken } from 'ha/utils/consistencyToken';
import { getLanguageFromPathname } from 'ha/utils/getLanguageFromUrl';
import { isIgnoredHosts } from 'ha/utils/isIgnoredHosts';

import { AppRoot } from 'ha/components/AppRoot';
import { getHaSwUrl } from 'ha/serviceWorker/utils/getHaSwUrl';
import { handleInstallPrompt } from 'ha/serviceWorker/utils/handleInstallPrompt';
import { initServiceWorker } from 'ha/serviceWorker/utils/initServiceWorker';

import { createRoutes } from './createRoutes';
import { sampleCommonErrors } from './helpers/sentry/filterErrors';
import {
  beforeSend,
  filterTransactions,
  groupTransactions,
} from './helpers/sentry/transactions';
import {
  AlternativeParentContextManager,
  defaultTextMapGetter,
} from './helpers/tracing/context-managers/AlternativeParentContextManager';
import { AppContextProvider } from './myredux/AppContextProvider';
import { createAppStore } from './myredux/createAppStore';

const logger = createLogger({
  level: config.debug ? 'debug' : 'info',
  name: 'tokamak',
});

/* eslint-disable no-underscore-dangle */
const preloadedState = (global.__PRELOADED_STATE__ as GlobalState) || {};
/* eslint-enable no-underscore-dangle */

const rootEl = document.getElementById('app-root');

const lang = getLanguageFromPathname(window.location.pathname);
const intl = createIntl({
  lang,
  // no need
  // translation are passed through babel-plugin-translations during build time
  messages: {},
  baseDomain: config.baseDomain,
  rmsBaseDomain: config.rms.baseDomain,
});

global.baseUrl = config.baseDomain;

const fetchOptions = {
  headers: {
    [HttpHeaders.Language]: lang,
  },
};

const consistencyToken = new ConsistencyToken();
const updateCnstToken = (response: Response) => {
  consistencyToken.updateConsistencyToken(Cookies, response);
};

const token = new Token();

const getAuthorizationHeader = () => token.getAuthorizationHeader();

const initSentry = (user: { id?: string; locale?: string }) => {
  if (config.sentry?.enabled && config.sentry?.dsn_react) {
    Sentry.init({
      tracesSampleRate: config.sentry.tracesSampleRate,
      sampleRate: config.sentry.sampleRate,
      normalizeDepth: config.sentry.normalizeDepth,
      dsn: config.sentry.dsn_react,
      environment: config.env,
      release: GIT_COMMIT,
      debug: config.sentry.debug,
      integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
      beforeSend: sampleCommonErrors(config.sentry.sampleCommonErrorsRate),
      beforeSendTransaction: beforeSend([
        groupTransactions([
          [/^\/\w{2}(\/.*)/, '$1'], // remove language locale
        ]),
        filterTransactions([
          /^\/$/, // home page
          /^\/s\//, // search page
          /^\/room\//, // LDP page
        ]),
        groupTransactions([
          [/^\/s\/.*/, '/s/*'], // group all searches under one endpoint
          [/^\/room\/.*/, '/room/*'], // group all LDPs under one endpoint
        ]),
      ]),
    });
    Sentry.setUser(user);
  }
};

const initWebOTEL = () => {
  if (!config.traceClient?.enabled || !config.traceClient?.collectorUrl) return;

  const resource = Resource.default().merge(
    new Resource({
      [SEMRESATTRS_SERVICE_NAMESPACE]: config.traceClient.serviceNamespace,
      [ATTR_SERVICE_NAME]: `${config.traceClient.serviceNamespace}-browser`,
      [ATTR_SERVICE_VERSION]: config.traceClient.serviceVersion,
    }),
  );

  const traceParent = document
    .querySelector('meta[name="traceparent"]')
    ?.getAttribute('content');

  if (!traceParent) {
    logger.warn('Parent trace missing - instrumentation will not start');
    return;
  }

  const provider = new WebTracerProvider({
    resource,
  });

  const exporter = new OTLPTraceExporter({
    url: config.traceClient?.collectorUrl,
  });

  provider.addSpanProcessor(new BatchSpanProcessor(exporter));

  if (process.env.NODE_ENV === Environment.DEVELOPMENT) {
    const developmentExporter = new ConsoleSpanExporter();
    provider.addSpanProcessor(new SimpleSpanProcessor(developmentExporter));
  }

  const propagator = new W3CTraceContextPropagator();
  const parentContext = propagator.extract(
    ROOT_CONTEXT,
    { traceparent: traceParent },
    defaultTextMapGetter,
  );

  const context = new AlternativeParentContextManager(parentContext);

  provider.register({
    propagator,
    contextManager: context,
  });

  registerInstrumentations({
    instrumentations: getWebAutoInstrumentations({
      // disable user interaction orchestration - we don't need it for now and it adds a lot of noise in traces
      '@opentelemetry/instrumentation-user-interaction': {
        enabled: false,
      },
    }),
  });
};

const getAlgoliaClient = () =>
  import(/* webpackChunkName: "algoliasearch" */ 'ha/helpers/algoliaWrapper')
    .then(module => module.algolia)
    .then(algoliaSDK =>
      algoliaSDK(config.algolia.appId, config.algolia.apiKey),
    );

const algolia = algoliaService({
  initializer: getAlgoliaClient,
  index: config.algolia.index,
});

// we need to use a reference approach because there is a circular dependency between router, services, and store
const routerRef: { current: ReturnType<typeof createBrowserRouter> | null } = {
  current: null,
};

const services = getAppServices({
  config,
  intl,
  clients: {
    algolia,
    haApi: new FetchClient(config.backend.host, {
      requestOptions: fetchOptions,
      getAuthorizationHeader,
      callback: updateCnstToken,
      requestHeaders: consistencyToken.getConsistencyTokensForHeaderClient,
    }),
    osiris: new FetchClient(
      /** @note the following should be `config.osiris.host` assuming it is defined as `envVars.OSIRIS_PUBLIC_URL` in similar way to other services, however it does not work */
      config.osiris.publicUrl,
      {
        getAuthorizationHeader,
      },
    ),
    metis: new FetchClient(config.metis.host, {
      requestOptions: {
        credentials: 'omit',
      },
    }),
    hermes: new FetchClient(config.hermes.host, {
      requestOptions: {
        credentials: 'omit',
      },
    }),
    almanac: new FetchClient(config.almanac.host, {
      requestOptions: {
        credentials: 'omit',
      },
    }),
    bell: new FetchClient(config.bell.host, {
      getAuthorizationHeader,
      requestHeaders: () => {
        const consistencyTokens =
          consistencyToken.getConsistencyTokensForHeaderClient();

        const anonymousId = (() => {
          // this is a workaround for `getAnonymousId` throwing an error when analytics SDK is not ready yet.
          try {
            return getAnonymousId();
          } catch {
            return '';
          }
        })();

        if (!consistencyToken && !anonymousId) {
          return null;
        }

        return {
          ...consistencyTokens,
          ...(anonymousId ? { [HttpHeaders.AnonymousId]: anonymousId } : {}),
        };
      },
    }),
    cookie: Cookies,
  },
  routerRef,
});

const store = createAppStore({
  config,
  initialState: getInitialState({
    preloadedState,
    language: lang,
  }),
  services,
});

const cookieConsentCtx = createConsentClient({
  domainKey: config.oneTrust.domainKey,
  withGoogleConsent: true,
});

window.addEventListener(
  'beforeinstallprompt',
  handleInstallPrompt({
    onChoice: choiceResult =>
      track('Web App Install Banner', { action: choiceResult.outcome }),
    onError: reportError,
  }),
);

Object.defineProperty(global, 'reduxStore', {
  get: () => store,
  set: () => {
    throw new Error('Trying to modify `window.reduxStore` property!');
  },
});

Promise.all([
  new Promise<void>(resolve => {
    loadableReady(resolve).catch(reportError);
  }),
  store.dispatch(initSession({ lang })),
])
  .catch(reportError)
  .then(() => {
    const userId = getUserId(store.getState());

    initSentry({ id: userId?.toString(), locale: lang });
    initWebOTEL();
  })
  .then(
    () =>
      new Promise<void>(resolve => {
        if (isIgnoredHosts(global.location.host)) {
          resolve();
        } else {
          const router = createBrowserRouter(
            createRoutes({ config, intl, store, services, logger }),
          );

          routerRef.current = router;

          const cache = createCache({ key: 'css', prepend: true });

          // eslint-disable-next-line react/no-deprecated
          ReactDOM.hydrate(
            <CacheProvider value={cache}>
              <AppContextProvider
                config={config}
                intl={intl}
                store={store}
                services={services}
                cookieConsentCtx={cookieConsentCtx}
              >
                <AppRoot>
                  <RouterProvider router={router} />
                </AppRoot>
              </AppContextProvider>
            </CacheProvider>,
            rootEl,
            resolve,
          );
        }
      }),
  )
  .then(() => {
    store.dispatch(setupMediaQueryListeners());
  })
  .then(() => {
    if ('serviceWorker' in global.navigator) {
      if (config.webApp.enableSW) {
        initServiceWorker(getHaSwUrl(config.baseDomain), {
          origin: global.location.origin,
          onError: reportError,
        });
      } else {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations => {
            registrations.forEach(registration => {
              registration.unregister().catch(reportError);
            });
          })
          .catch(reportError);
      }
    }
  })
  .then(() => {
    trackTouchPoint(new URL(window.location.href))
      .then(touchPointsList =>
        store.dispatch(sendTouchPointsList(touchPointsList)),
      )
      .catch(reportError)
      .finally(() => session.updateTime());

    store.dispatch(
      setupAnalytics({
        url: new URL(window.location.href),
        referrer: global.document.referrer,
      }),
    );
  })
  .then(() => {
    global.appReady = true;
  })
  .catch(reportError);
