export enum PagesType {
  About = 'About',
  Blog = 'Blog',
  Cities = 'Cities',
  Community = 'Community',
  Country = 'Country',
  Feature = 'Feature',
  Guide = 'Guide',
  HelpCenter = 'HelpCenter',
  Home = 'Home',
  HowTo = 'HowTo',
  LandlordBlog = 'LandlordBlog',
  PrivateLandlordLanding = 'PrivateLandlordLanding',
  Payments = 'Payments',
  Press = 'Press',
  Search = 'Search',
  SupplyLanding = 'SupplyLanding',
  TenantBlog = 'TenantBlog',
  University = 'University',
  UniversitySignUp = 'UniversitySignUp',
}

export enum TenantBlogPages {
  GLOBAL = 'Global',
  COUNTRY = 'Country',
  CITY = 'City',
}

export enum BlogSlugs {
  Homepage = 'blog',
  Tenants = 'tenants',
  Landlords = 'landlords',
}
